import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateCouponForm from './CreateCouponForm';

const CreateCouponFormModal = ({
  modalOpened,
  setModalOpened,
  selectedCoupon,
  setSelectedCoupon,
  incrementFetchCount
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      destroyOnClose
      transitionName=""
      className="product-form-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '542px' }}
      title={
        <div className="modal-title">
          {selectedCoupon ? t('coupon_form.update') : t('coupon_form.create')}
        </div>
      }
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedCoupon(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedCoupon(null);
      }}
      footer={false}
    >
      <CreateCouponForm
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={setSelectedCoupon}
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        incrementFetchCount={incrementFetchCount}
      />
    </Modal>
  );
};

export default CreateCouponFormModal;
