import { LoadingOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HrArrowLtr from '../../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import HrArrowRtl from '../../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import CustomBreadcrumb from '../../../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../../../components/app/routerLinks';
import productTypes from '../../../../const-values/productTypes';
import useMostOrderedProducts from '../../../../custom-hooks/useMostOrderedProducts';
import ProductCard from '../../products-page/ProductCard';
import PageChart from './PageChart';
import './styles.scss';

const MostOrderedProductsPage = () => {
  const sharedT = (key, options) => t(`merchant_statistics.${key}`, options);
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const {
    isLoadingTableData,
    setFetchTableDataCount,
    //
    allFetchedTableData
  } = useMostOrderedProducts();

  const renderProductsCards = () => {
    if (isLoadingTableData) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (allFetchedTableData && allFetchedTableData?.data?.length > 0) {
      return (
        <div className="products-wrap">
          {allFetchedTableData.data.map((obj, index) => (
            <ProductCard
              type={productTypes?.merchentProduct}
              key={index}
              card={obj}
              setFetchParentCount={setFetchTableDataCount}
            />
          ))}
        </div>
      );
    }
    return <Empty description={false}>{t('productsPage.empty')}</Empty>;
  };

  return (
    <div className="most-ordered-products-page">
      <div className="breadcrumb-title">
        <CustomBreadcrumb
          arr={[
            {
              title: t('main_app_bar_links.home'),
              isLink: true,
              to: routerLinks?.homePage
            },
            {
              title: t('merchentDashboard.statistics'),
              isLink: true,
              to: routerLinks.merchentStatistics
            },
            {
              title: sharedT('most_ordered_products'),
              isLink: false
            }
          ]}
        />
      </div>

      <div className="custom-container">
        <div className="page-content">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">
              {sharedT('most_ordered_products')}
            </div>
          </div>

          <div className="content-chart-wrapper">
            {renderProductsCards()}
            {!isLoadingTableData && (
              <PageChart
                max={allFetchedTableData.max}
                result={allFetchedTableData.result}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostOrderedProductsPage;
