/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Empty, Pagination } from 'antd';
import queryString from 'query-string';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import getAllModelsOrdersApi from '../../../apis/models-apis/getAllModelsOrdersApi';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';
import accountTypes from '../../../const-values/accountTypes';
import ModelsOrdersContext from '../../../contexts/models-orders-context/ModelsOrdersContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import UserOrderCard from './ModelOrderCard';
import OrderReceiptModal from './OrderReceiptModal';
import './styles.scss';

const ModelsOrdersPage = () => {
  const { user } = useContext(UserContext);
  const {
    isLoadingOrders,
    setIsLoadingOrders,
    isRating,
    ordersFilter,
    fetchOrdersCount,
    allFetchedOrders,
    setAllFetchedOrders,
    setOrdersPagination,
    isCancelling,
    setFetchOrdersCount,
    ordersPagination,
    receiptModalOpened
  } = useContext(ModelsOrdersContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);

  const customApiRequest = useCustomApiRequest();
  //
  const chooseRequest = () => {
    customApiRequest(
      getAllModelsOrdersApi(
        {
          ...ordersFilter,
          ...values
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        window.scrollTo(0, 0);
        setIsLoadingOrders(false);
        if (checkRes(res) && res.data?.data?.data?.length >= 0) {
          const data = res.data.data.data;
          setAllFetchedOrders(data);
          if (res.data.data?.pagination) {
            setOrdersPagination(res.data.data.pagination);
          }
        } else {
          setAllFetchedOrders(null);
        }
      },
      (error) => {
        setIsLoadingOrders(false);
      }
    );
  };

  //

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setIsLoadingOrders(true);
      chooseRequest();
    }

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchOrdersCount, ordersFilter, search]);

  const renderPageContent = () => {
    if (isLoadingOrders && fetchOrdersCount === 0) {
      return (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            padding: '132px 0',
            margin: '32px auto'
          }}
        >
          {/* <LoadingOutlined /> */}
          <LoadingModal />
        </div>
      );
    } else if (allFetchedOrders?.length === 0)
      return (
        <div className="custom-container">
          <div
            style={{
              display: 'grid',
              padding: '52px 22px'
            }}
          >
            <Empty description={false}>You don't have any orders yet !!!</Empty>
          </div>
        </div>
      );
    else if (allFetchedOrders?.length > 0)
      return (
        <>
          <ul className="orders-ul">
            {allFetchedOrders.map((card) => (
              <UserOrderCard key={card?.id} card={card} />
            ))}
          </ul>

          {ordersPagination?.total > 0 && (
            <Pagination
              locale={{
                jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                page: ''
              }}
              showSizeChanger={false}
              defaultCurrent={1}
              current={ordersPagination.current_page}
              pageSize={ordersPagination.per_page}
              total={ordersPagination.total}
              // itemRender={itemRender}
              onChange={(page, pageSize) => {
                setFetchOrdersCount((prev) => prev + 1);
                navigate(`${routerLinks?.modelsOrders}?page=${page}`);
              }}
              hideOnSinglePage={true}
            />
          )}
        </>
      );

    return null;
  };

  const sharedTrans = (key) => t(`userDashboard.${key}`);
  return (
    <div className="user-orders-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to:
              user?.accountType == accountTypes()?.user
                ? userRouterLinks?.userDashboard
                : merchentRouterLinks?.merchentDashboard
          },
          {
            title: t('modelOrdersPage.title'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-arrow-wrap">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            {i18n.dir() === 'rtl' ? (
              <img src={HrArrowRtl} alt="back" />
            ) : (
              <img src={HrArrowLtr} alt="back" />
            )}
          </button>
          <div className="main-page-title">{t('modelOrdersPage.title')}</div>
        </div>
        <div className="page-body">{renderPageContent()}</div>
      </div>

      {receiptModalOpened && <OrderReceiptModal />}
      {(isLoadingOrders || isRating || isCancelling) && <LoadingModal />}
    </div>
  );
};

export default ModelsOrdersPage;
