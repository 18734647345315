/* eslint-disable eqeqeq */
import { memo } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import CustomImage from '../../../common/custom-image/CustomImage';
import discountCodeTypes from '../../../const-values/discountCodeTypes';
import {
  checkUrlExtenstion,
  urlTypes
} from '../../../utils/checkUrlExtenstion';
import SingleModelForm from './SingleModelForm';
// import { isImgLink } from '../../../utils/checkFiles';

const SingleModelData = ({
  isLoadingSingleProduct,
  setIsLoading,
  fetchedSingleModel
}) => {
  const { t } = useTranslation();

  const renderGalleryImages = () => {
    let mapArr = [
      {
        id: 'freeImage',
        image: fetchedSingleModel.freeImage
      },
      {
        id: 'freeIntroductoryVideo',
        image: fetchedSingleModel.freeIntroductoryVideo
      },
      {
        id: 'freeServiceVideo',
        image: fetchedSingleModel.freeServiceVideo
      }
    ];
    return mapArr.map((img) => {
      return {
        id: img?.id || 'img_id',
        original: img?.image,
        thumbnail: img?.image
      };
    });
  };

  const renderDiscontBadge = () => {
    if (fetchedSingleModel?.hasCode) {
      if (fetchedSingleModel?.codeType == discountCodeTypes?.fixedValue) {
        return (
          <div className="discount-badge">
            {fetchedSingleModel?.discountValue}
            {t('currency.sar')} {t('off')}
          </div>
        );
      } else if (
        fetchedSingleModel?.codeType == discountCodeTypes?.percentageValue
      ) {
        return (
          <div className="discount-badge">
            {fetchedSingleModel?.discountValue}% {t('off')}
          </div>
        );
      }
    }
  };

  const GalleryItem = memo(({ item }) => {
    // if (isImgLink(item?.original))
    if (checkUrlExtenstion(item?.original) === urlTypes?.imageUrl)
      return (
        <img className="image-gallery-image" src={item?.original} alt="alt" />
      );
    else if (checkUrlExtenstion(item?.original) === urlTypes?.videoUrl)
      return (
        <video controls autoPlay muted className="video-gallery">
          <source src={item?.original} />
        </video>
      );
  });
  const GalleryThumbnail = memo(({ item }) => {
    // if (isImgLink(item?.thumbnail))
    if (
      checkUrlExtenstion(item?.original) === urlTypes?.imageUrl &&
      item?.id !== 'video_key'
    )
      return (
        <img
          className="image-gallery-thumbnail-image"
          src={item?.thumbnail}
          alt="alt"
        />
      );
    else if (checkUrlExtenstion(item?.original))
      return (
        <CustomImage
          src={fetchedSingleModel?.logo}
          className="video-thumbnail"
        />
      );
  });

  return (
    <section className="product-details-section">
      <div className="gallery-details-wrap">
        <div className="gallery-wrap">
          {renderDiscontBadge()}
          {fetchedSingleModel?.hasCode &&
            fetchedSingleModel?.discountEndDate && (
              <div className="discount-countdown-badge">
                <Countdown
                  date={new Date(fetchedSingleModel.discountEndDate).getTime()}
                />
              </div>
            )}
          <ImageGallery
            showFullscreenButton={false}
            showPlayButton={false}
            autoPlay={false}
            renderItem={(item) => {
              return <GalleryItem item={item} />;
            }}
            renderThumbInner={(item) => {
              return <GalleryThumbnail item={item} />;
            }}
            items={renderGalleryImages()}
          />
        </div>

        <div className="details-parent-wrap">
          <div className="name-actions-wrap">
            <div className="filter-name-wrap">
              <p className="cat-catFilter">
                {fetchedSingleModel?.cat} - {fetchedSingleModel?.catFilter}
              </p>
              <p className="p-title">
                {fetchedSingleModel?.name}
                <span className="stock-label">
                  {fetchedSingleModel?.inStock > 0 ? (
                    <span className="in-stock">
                      {t('singleProductPage.inStock')}
                    </span>
                  ) : (
                    <span className="out-of-stock">
                      {t('singleProductPage.outOfStock')}
                    </span>
                  )}
                </span>
              </p>
            </div>

            {fetchedSingleModel?.description && (
              <div className="model-desc">
                <div className="desc-content">
                  {fetchedSingleModel?.description}
                </div>
              </div>
            )}
          </div>

          <SingleModelForm
            fetchedSingleModel={fetchedSingleModel}
            isLoadingSingleProduct={isLoadingSingleProduct}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
    </section>
  );
};

export default SingleModelData;
