/* eslint-disable eqeqeq */
import { EditOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import CopyIcon from '../../../common/icons/PercentageIcon copy';
import './MerchantCouponCard.scss';

const MerchantCouponCard = ({
  card,
  setCopied,
  setCopyCount,
  setModalOpened,
  setSelectedCoupon
}) => {
  const { t } = useTranslation();
  const trans = (key) => t(`couponsPage.${key}`);
  return (
    <div className="merchant-coupon-card">
      <button
        className="edit-btn"
        type="button"
        onClick={() => {
          setSelectedCoupon(card);
          setModalOpened(true);
        }}
      >
        <EditOutlined />
      </button>
      <p className="amount-p">
        <span>
          {card?.amount_type == '1'
            ? `${Number(card.amount)} ${t('currency.sar')}`
            : `${Number(card.amount)}%`}
        </span>
      </p>
      <p className="card-name">{card?.name}</p>

      <p className="exp-date">
        <span className="label-span">{trans('exp_date')} : </span>
        <span className="exp-date">{card?.expire_date}</span>
      </p>
      {!!card?.store_name && <p className="store-name">{card?.store_name}</p>}
      <p className={`is-use ${card?.is_use == '1' ? 'used' : 'not-used'}`}>
        {card?.is_use == '1' ? trans('used') : trans('not_used')}
      </p>

      <CopyToClipboard text={card?.code ?? ''} onCopy={() => setCopied(true)}>
        <button
          className="copy-btn copy"
          onClick={() => setCopyCount((prev) => prev + 1)}
        >
          {card.code}
          <CopyIcon />
        </button>
      </CopyToClipboard>
    </div>
  );
};

export default MerchantCouponCard;
