export const modelPricesOptionsValues = {
  priceWithImages: '1',
  priceWithIntroductoryVideo: '3',
  priceWithServiceVideo: '2'
};

const modelPricesOptionsList = (t, fetchedSingleModel) => {
  return [
    {
      id: '1',
      label: `${t('priceWithImages')} : ${Number(
        fetchedSingleModel?.priceWithImages
      )} ${t('currency.sar')}`,
      value: modelPricesOptionsValues.priceWithImages,
      key: 'priceWithImages'
    },
    {
      id: '2',
      label: `${t('priceWithIntroductoryVideo')} : ${Number(
        fetchedSingleModel?.priceWithIntroductoryVideo
      )} ${t('currency.sar')}`,
      value: modelPricesOptionsValues.priceWithIntroductoryVideo,
      key: 'priceWithIntroductoryVideo'
    },
    {
      id: '3',
      label: `${t('priceWithServiceVideo')} : ${Number(
        fetchedSingleModel?.priceWithServiceVideo
      )} ${t('currency.sar')}`,
      value: modelPricesOptionsValues.priceWithServiceVideo,
      key: 'priceWithServiceVideo'
    }
  ];
};

export default modelPricesOptionsList;
