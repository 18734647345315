/* eslint-disable eqeqeq */
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import CopyIcon from '../../../common/icons/PercentageIcon copy';
import './UserCouponCard.scss';

const UserCouponCard = ({ card, setCopied, setCopyCount }) => {
  const { t } = useTranslation();
  const trans = (key) => t(`couponsPage.${key}`);
  return (
    <div className="user-coupon-card">
      <p className="amount-p">
        {/* <span>{trans('amount')} : </span> */}
        <span>
          {card?.amount_type == '1'
            ? `${Number(card.amount)} ${t('currency.sar')}`
            : `${Number(card.amount)}%`}
        </span>
      </p>
      <p className="card-name">{card?.name}</p>

      <p className="exp-date">
        <span className="label-span">{trans('exp_date')} : </span>
        <span className="exp-date">{card?.expire_date}</span>
      </p>
      {!!card?.store_name && <p className="store-name">{card?.store_name}</p>}
      <p className={`is-use ${card?.is_use == '1' ? 'used' : 'not-used'}`}>
        {card?.is_use == '1' ? trans('used') : trans('not_used')}
      </p>

      <CopyToClipboard text={card?.code ?? ''} onCopy={() => setCopied(true)}>
        <button
          className="copy-btn copy"
          onClick={() => setCopyCount((prev) => prev + 1)}
        >
          {card.code}
          <CopyIcon />
        </button>
      </CopyToClipboard>
    </div>
  );
};

export default UserCouponCard;
