import * as Yup from 'yup';
import discountCodeTypes from '../../../const-values/discountCodeTypes';

const baseRequiredErrTransString = (t, key) =>
  t(`coupon_form.${key}.errors.required`);
// const baseErrorTrans = (t, key, errType, options) =>
//   t(`coupon_form.${key}.errors.${errType}`, options);

const sharedSchema = (t) => {
  return {
    code: Yup.string().required(baseRequiredErrTransString(t, 'code')),
    discount_type: Yup.string().test(
      'discount_type',
      baseRequiredErrTransString(t, 'discount_type'),
      (v, context) => {
        let result = true;
        if (!v || !v.match(/^(\d+)?(\.\d+)?$/)) result = false;
        return result;
      }
    ),
    amount: Yup.string()
      .test(
        'amount',
        t('coupon_form.fixedValue.errors.invalid'),
        (v, context) => {
          let result = true;
          if (
            context?.parent?.discount_type == discountCodeTypes?.fixedValue &&
            (!v || !v.match(/^(\d+)?(\.\d+)?$/))
          )
            result = false;
          return result;
        }
      )
      .test(
        'amount',
        t('coupon_form.percentageValue.errors.invalid'),
        (v, context) => {
          let result = true;
          if (
            context?.parent?.discount_type ==
              discountCodeTypes?.percentageValue &&
            (!v || !v.match(/^(\d+)?(\.\d+)?$/) || parseFloat(v) >= 100)
          )
            result = false;
          return result;
        }
      ),
    expire_date: Yup.mixed().test(
      'expire_date',
      baseRequiredErrTransString(t, 'expire_date'),
      (v) => v
    )
  };
};
const createNewFormSchema = (t) =>
  Yup.object().shape({
    ...sharedSchema(t)
  });
const editFormSchema = (t) => {
  return Yup.object().shape({
    ...sharedSchema(t)
  });
};
const couponFormSchema = (selectedCoupon, t) => {
  if (!selectedCoupon) {
    return createNewFormSchema(t);
  }
  return editFormSchema(t);
};

export default couponFormSchema;
