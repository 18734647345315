/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import paymentTypes, {
  paymentStatusValues
} from '../../../const-values/paymentTypes';
import ModelsOrdersContext from '../../../contexts/models-orders-context/ModelsOrdersContext';
import './OrderReceipt.scss';

const OrderReceipt = React.forwardRef((props, ref) => {
  const { selectedOrder: selectedReceipt } = useContext(ModelsOrdersContext);
  const { t, i18n } = useTranslation();
  const sharedT = (key) => t(`orderReceipt.${key}`);
  //
  //
  //
  //
  const renderDiscountTitle = (p) => {
    if (p?.hasCode) {
      return (
        <p
          style={{
            fontSize: 11,
            color: '#000'
          }}
          className="discount-p"
        >
          {t('discount')}
        </p>
      );
    }
    return null;
  };

  const renderDiscountValue = (p) => {
    if (p?.hasCode && p?.discountValue) {
      return <p className="discount-v">{p.discountValue}</p>;
    }
    return null;
  };
  //
  //
  //
  //
  //

  const renderProducts = () => {
    return (
      <div className="products-table-total-wrap">
        <table
          className="invoice-products-table"
          style={{
            fontFamily: `DroidKufi-Regular, Poppins-Regular !important`,
            width: 'calc(100% - 36px)',
            margin: '0 10px',
            padding: 0,
            textAlign: i18n.dir() === 'rtl' ? 'right' : 'left',
            borderCollapse: 'separate',
            borderCpacing: '4px 10px'
          }}
        >
          <thead>
            <tr className="invoice-products-head">
              <th
                style={{
                  minWidth: '118px',
                  padding: '2px',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-name-cell"
              >
                {sharedT('productClass')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {sharedT('price')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {sharedT('productTotal')}
              </th>
            </tr>
          </thead>
          <tbody>{renderInvoiceProduct()}</tbody>
        </table>

        <table
          className="prices-table"
          style={{
            width: 'calc(100% - 36px)',
            borderTop: '1px dashed #000',
            margin: 'auto',
            textAlign: i18n.dir() === 'rtl' ? 'right' : 'left',
            borderCollapse: 'separate',
            borderSpacing: '4px 8px',
            color: '#000'
          }}
        >
          <tbody>
            {selectedReceipt.productPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {sharedT('subTotal')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.productPrice}
                </td>
              </tr>
            )}
            {selectedReceipt.discountPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px 4px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {sharedT('discountPrice')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.discountPrice}
                </td>
              </tr>
            )}

            {selectedReceipt.totalBeforeTax > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {sharedT('tax')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.totalBeforeTax}
                </td>
              </tr>
            )}
            {selectedReceipt.taxPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {sharedT('tax')}
                  {' : '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.taxPrice}
                </td>
              </tr>
            )}
            {selectedReceipt.totalPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {sharedT('grandTotal')}
                  {' : '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.totalPrice}
                </td>
              </tr>
            )}
            {selectedReceipt.returnPrice &&
              parseFloat(selectedReceipt.returnPrice) > 0 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      width: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    المبلغ المرتجع :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px' }}
                    className="invoice-table-cell discount-value"
                  >
                    {selectedReceipt.returnPrice}
                  </td>
                </tr>
              )}
            {selectedReceipt.totalPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {sharedT('paymentMethod')}
                  {' : '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000', fontSize: '14px' }}
                  className="invoice-table-cell discount-value"
                >
                  {paymentTypes(t)?.find(
                    (obj) => obj.value == selectedReceipt?.paymentType
                  )?.name ?? ''}
                  {selectedReceipt?.paymentStatus ==
                    paymentStatusValues.not_paid && (
                    <p style={{ fontSize: 11, color: 'red' }}>
                      ( {t('not_paid')} )
                    </p>
                  )}
                </td>
              </tr>
            )}

            {/* {selectedReceipt.returnTax &&
              parseFloat(selectedReceipt.returnTax) > 0 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      width: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    الضريبة المرتجعة :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px' }}
                    className="invoice-table-cell discount-value"
                  >
                    {selectedReceipt.returnTax}
                  </td>
                </tr>
              )} */}
          </tbody>
        </table>
        {/* {invoiceTotalPriceAfterTax > 0 && (
          <div className="grand-total-invoice-price">
            <span>المجموع الكلى</span>
            <span className="bold-font">{invoiceTotalPriceAfterTax}</span>
          </div>
        )} */}

        {/* <div className="qr-code-img-wrap">
          <img className="qrcode-img" src={qrCodeImg} alt="qrcode" />
        </div> */}

        <div
          style={{
            textAlign: 'center',
            padding: '0 8px 10px 8px'
          }}
        >
          {/* {systemSettings?.slogan ? systemSettings.slogan : ''} */}
          {selectedReceipt?.setting?.slogan
            ? selectedReceipt.setting.slogan
            : ''}
        </div>
      </div>
    );
  };

  const renderInvoiceProduct = () => {
    const product = selectedReceipt?.product;
    if (product)
      return (
        <tr key={product.id} className="invoice-product-li">
          <td
            style={{
              verticalAlign: 'top',
              padding: '2px',
              fontSize: '13px',
              color: '#000'
            }}
            className="invoice-table-cell product-name-cell"
          >
            {product?.name ?? '----'}
            {renderDiscountTitle(product)}
          </td>
          <td
            style={{ verticalAlign: 'top', padding: '2px', color: '#000' }}
            className="invoice-table-cell product-price-cell"
          >
            {selectedReceipt?.productPrice
              ? parseFloat(selectedReceipt.productPrice)
              : '----'}
            {renderDiscountValue(product)}
          </td>
          <td
            style={{ verticalAlign: 'top', padding: '2px', color: '#000' }}
            className="invoice-table-cell product-price-cell"
          >
            {selectedReceipt?.totalPrice
              ? parseFloat(selectedReceipt.totalPrice)
              : '----'}
          </td>
        </tr>
      );
    return null;
  };

  return (
    <div
      ref={ref}
      className="print-model-whole-receipt-wrap"
      style={{
        direction: i18n.dir()
      }}
    >
      <div
        className="receipt-wrap"
        style={{
          fontFamily: `DroidKufi-Regular, Poppins-Regular !important`,
          height: 'max-content',
          color: '#000',
          position: 'relative'
        }}
      >
        <img
          style={{
            position: 'absolute',
            left: 0,
            height: 44
          }}
          className="naomi-logo"
          src="/assets/imgs/logo/logo.png"
          alt="naomi"
        />
        <div
          className="store-logo-name-wrap"
          style={{
            display: 'grid',
            placeItems: 'center',
            minWidth: '92px',
            minHeight: '92px'
          }}
        >
          {selectedReceipt?.store?.logo ? (
            <img
              className="system-logo"
              src={selectedReceipt?.store?.logo}
              alt="logo"
              style={{
                // width: '92px',
                height: '92px'
              }}
            />
          ) : null}

          <div className="store-name">
            {selectedReceipt?.user?.username || '---'}
          </div>
        </div>

        <div className="invoice-content">
          <div className="invoice-number">
            <p className="number-span" style={{ color: '#000' }}>
              {sharedT('receiptNumber')} :{' '}
              {selectedReceipt?.id ? '00' + selectedReceipt.id : ''}
            </p>
          </div>
          <div
            className="system-info"
            style={{
              margin: '4px 10px',
              paddingBottom: '12px',
              marginBottom: '0',
              borderBottom: '1px dashed #000',
              gap: '12px 4px',
              color: '#000',
              fontSize: '13px'
            }}
          >
            {selectedReceipt?.store?.address && (
              <p
                style={{
                  textAlign: 'center',
                  marginTop: 8
                }}
              >
                {selectedReceipt.store.address}
              </p>
            )}
            {selectedReceipt?.created_at && (
              <p
                style={{
                  textAlign: 'center',
                  marginTop: 8
                }}
              >
                {selectedReceipt.created_at}
              </p>
            )}
          </div>

          {renderProducts()}
        </div>
      </div>
    </div>
  );
});

export default OrderReceipt;
