/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  // Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import StoreStatisticsContext from '../../../contexts/store-statistics-context/StoreStatisticsContext';
import './MerchentStoreStatisticsChart.scss';

const MerchentStoreStatisticsChart = () => {
  const { fetchedData } = React.useContext(StoreStatisticsContext);
  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    const arr = [];
    for (let i = 1; i <= 6; i++) {
      arr.push({
        date: fetchedData?.clientsOrders[i]?.range,
        orders: fetchedData?.clientsOrders[i]?.numberOfOrders,
        price: fetchedData?.clientsOrders[i]?.price
      });
    }
    setChartData(arr);
  }, [fetchedData]);

  return (
    <>
      <div style={{ width: '100%', height: 478, direction: 'ltr' }}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            // width={900}
            // height={300}
            // data={data}
            data={chartData}
            margin={{
              top: 5,
              right: 22,
              left: -8,
              bottom: 5
            }}
          >
            <defs>
              <linearGradient id={'colorUv' + 1} x1="0" y1="0" x2="0" y2="1">
                <stop offset="100%" stopColor="#9d4edd" />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date">
              {/* <Label value="Goal type" offset={-8} position="insideBottom" /> */}
            </XAxis>
            <YAxis />
            <Tooltip />
            <Legend />
            <Area
              type="monotone"
              dataKey="price"
              strokeWidth="2"
              // stroke="#8884d8"
              stroke="#9d4edd"
              fill="#d4b1f1"
              activeDot={{ r: 8 }}
            />
            {/* <Line type="monotone" dataKey="purchases" stroke="#82ca9d" /> */}
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div
        style={{ width: '100%', height: 478, direction: 'ltr', marginTop: 22 }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            // width={900}
            // height={300}
            // data={data}
            data={chartData}
            margin={{
              top: 5,
              right: 22,
              left: -8,
              bottom: 5
            }}
          >
            <defs>
              <linearGradient id={'colorUv' + 1} x1="0" y1="0" x2="0" y2="1">
                <stop offset="100%" stopColor="#9d4edd" />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date">
              {/* <Label value="Goal type" offset={-8} position="insideBottom" /> */}
            </XAxis>
            <YAxis />
            <Tooltip />
            <Legend />
            <Area
              type="monotone"
              dataKey="orders"
              strokeWidth="2"
              // stroke="#8884d8"
              stroke="#fb2e86"
              fill="#febed9"
              activeDot={{ r: 8 }}
            />
            {/* <Line type="monotone" dataKey="purchases" stroke="#82ca9d" /> */}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default MerchentStoreStatisticsChart;
