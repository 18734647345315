/* eslint-disable eqeqeq */
import { Modal } from 'antd';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import receiptIcon from '../../../assets/imgs/icons/receipt.png';
import ModelsOrdersContext from '../../../contexts/models-orders-context/ModelsOrdersContext';
import OrderReceipt from './OrderReceipt';
import './OrderReceiptModal.scss';

const OrderReceiptModal = () => {
  const { setSelectedOrder, receiptModalOpened, setReceiptModalOpened } =
    useContext(ModelsOrdersContext);
  const posRef = useRef();
  const { t } = useTranslation();

  const handlePrintPOS = useReactToPrint({
    content: () => posRef?.current,
    pageStyle: `
    @media print {
      @page {
        size: 200mm auto;
        margin: none;
      }
      .print-whole-receipt-wrap {
        border: 0 !important;
      }
      .receipt-wrap {
        direction: rtl !important;
        background-color: #fff;
        border-radius: 10px;
        color: "#000 !important";
      }

      .store-logo-name-wrap {
        padding-top: 6px;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .store-name {
        margin-top: 2px;
        color: #000;
        font-size: 18px;
        padding: 0 4px;
        text-align: center;
      }

      .number-span {
        padding: 0 4px;
        text-align: center;
      }
      .invoice-number {
        margin-top: 2px;
      }

      .system-info {
        padding-bottom: 4px;
        margin: 4px;
        margin-bpttom: 0px;
        border-bottom: 1px dashed #000;
        gap: 5px 4px;
        color: #000;
        font-size: 15px;
      }

      .cell-wrap {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 2px;
      }
      .date-time-wrap {
        display: flex;
        justify-content: space-between;
      }
      .date-time-wrap .cell-wrap  {
        margin-left: 28px;
      }
      .info-cell-value {
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        text-overflow: ellipsis;
      }

      .info-cell-title {
        font-size: 12px;
      }
    `
  });

  return (
    <Modal
      className="shared-custom-modal model-order-receipt-modal"
      width="90%"
      style={{ maxWidth: '542px' }}
      title={t('printOrderModal.title')}
      open={receiptModalOpened}
      onOk={() => {
        setReceiptModalOpened(false);
        setSelectedOrder(null);
      }}
      onCancel={() => {
        setReceiptModalOpened(false);
        setSelectedOrder(null);
      }}
      footer={false}
    >
      <OrderReceipt ref={posRef} />

      <button onClick={handlePrintPOS} className="receipt-btn">
        <img src={receiptIcon} alt="" />
        {t('printOrderModal.title')}
      </button>
    </Modal>
  );
};

export default OrderReceiptModal;
